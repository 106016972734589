#loaderBg {
    background-color: #fff;
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

#preloader {
    border-radius: 50%;
    width: 120px;
    height: 120px;
    background: conic-gradient(rgba(#282828, 0.04), rgba(#282828, 0.05), #000);
    display: flex;
    align-items: center;
    justify-content: center;
    animation: spin 1s linear infinite;

    &::before {
        content: '';
        width: 90%;
        height: 90%;
        border-radius: 50%;
        background-color: #fff;
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

#spinning {
    position: relative;
}

#logo {
    position: absolute;
    height: 35px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
